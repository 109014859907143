import { graphql, useStaticQuery } from "gatsby"
import React, { Fragment } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import style from "./404.module.scss"
import { GatsbySrcImage } from "../components/GatsbyImage"
import pageNotFoundImage from "../../src/images/404-robot.png"
import arrow from "../images/chevron.svg"
import Layout from "../components/Layout"

const PageNotFound = () => {
  const data = useStaticQuery(
    graphql`
      query {
        pageNotFound: file(relativePath: { eq: "404-robot.png" }) {
          childImageSharp {
            fluid(maxWidth: 1920, pngQuality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const heading = "404 <br>"
  const subHeading = "Page not found"
  const mainText =
    "Oops!<br />" +
    "The link you followed may be broken, or the page may have been removed."
  const linkText = "Take me back to Little Village"

  return (
    <Layout
      seoComponent={{ seoTitle: "404 error", seoDescription: "Page not found" }}
      location={{ pathname: "page-not-found" }}
    >
      <div className={`${style.mainContainer} py-5 bg-primary`}>
        <section className="h-100 p-0">
          <Container className="h-100">
            <Row className="h-100 d-flex justify-content-md-center  align-content-lg-center">
              <Col
                xs={12}
                sm={9}
                lg={7}
                xl={6}
                className={`order-1 order-xl-0 col-xxl-5 d-flex flex-column justify-content-center align-items-center align-items-xl-start`}
              >
                <hgroup>
                  <h1
                    className={`${style.heading} pt-5 pt-xl-0 text-white text-xl-left`}
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <p
                    className={`${style.subHeading} h1 text-white text-xl-left `}
                    dangerouslySetInnerHTML={{ __html: subHeading }}
                  />
                </hgroup>
                <div
                  className={`${style.text} py-4 h5 text-white text-xl-left`}
                  dangerouslySetInnerHTML={{ __html: mainText }}
                />
                <p className={style.arrow}>
                  <a className={`h5 text-white`} href="/">
                    {linkText}
                    <img
                      className={`pl-3 pb-1`}
                      src={arrow}
                      alt="right arrow"
                    />
                  </a>
                </p>
              </Col>
              <Col
                xs={12}
                sm={9}
                lg={7}
                xl={5}
                className="order-0 order-xl-1 col-xxl-5 d-flex flex-column justify-content-start justify-content-lg-center align-items-center mb-xl-5 ml-xxl-9"
              >
                <img src={pageNotFoundImage} className="w-75" alt="page not found image" />
              </Col>
            </Row>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default PageNotFound
